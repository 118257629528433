<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <div class="mt-2">
          <h3>Dados básicos da filial</h3>
          <v-divider></v-divider>
        </div>

        <v-row class="mt-2">
          <v-col>
            <v-text-field
              class="required"
              v-model="form.companyName"
              label="Razão social"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              class="required"
              v-model="form.fantasyName"
              label="Nome fantasia"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              class="required"
              v-model="form.taxDocument"
              label="CNPJ"
              v-mask="['##.###.###/####-##']"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="email"
              v-model="form.email"
              label="E-Mail:"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-mask="'(##) ####-####'"
              v-model="form.phone"
              label="Telefone:"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-mask="'(##) # ####-####'"
              v-model="form.cellphone"
              label="Celular:"
            ></v-text-field>
          </v-col>
        </v-row>

        <div class="mt-2">
          <h3>Endereço da filial</h3>
          <v-divider></v-divider>
        </div>

        <v-row class="mt-2">
          <v-col>
            <v-text-field
              label="Cep:"
              v-model="form.zipCode"
              placeholder="xxxxx-xxx"
              v-mask="'#####-###'"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-select
              v-model="form.CityId"
              label="Cidade:"
              :items="cities"
              :loading="loadingCities"
              class="required"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <v-text-field
              label="Logradouro"
              v-model="form.address"
              maxlength="254"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              label="Número"
              v-model="form.addressNumber"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="8">
            <v-text-field label="Bairro" v-model="form.district"></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Complemento"
              v-model="form.addressComplement"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template #actions></template>
    </BaseForm>

    <v-dialog v-model="successDialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <div class="text-center pa-4">
            <v-icon large color="success">mdi-check</v-icon>
          </div>

          <div class="text-center pa-3">
            <h3>Dados atualizados com sucesso</h3>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeSuccessDialog">Fechar</v-btn></v-card-actions
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";

export default {
  directives: { mask },
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    form: {},
    successDialog: false,
    actionAfterOperation: null,
    loadingCities: false,
    cities: [],
  }),
  computed: {},
  methods: {
    validate() {
      const errors = [];

      if (!this.form.companyName)
        errors.push("O campo RAZÃO SOCIAL é obrigatório");

      if (!this.form.fantasyName)
        errors.push("O campo NOME FANTASIA é obrigatório");

      if (!this.form.taxDocument) errors.push("O campo CNPJ é obrigatório");

      if (!this.form.CityId) errors.push("O campo CIDADE é obrigatório");

      return errors;
    },

    save(actionAfterOperation) {
      // console.log(this.form);

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/company-branches`, this.form);

        this.clearForm();

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(
          `/company-branches/${this.$route.params.id}`,
          this.form
        );

        this.successDialog = true;
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },

    async loadCompanyBranchData() {
      try {
        this.loadingPermissions = true;

        const response = await this.$axios.get(
          `/company-branches/${this.$route.params.id}`
        );

        this.setCompanyBranchData(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingPermissions = false;
      }
    },

    setCompanyBranchData(data) {
      const cpData = { ...data };

      this.form = cpData;
    },

    async loadCities() {
      try {
        this.loadingCities = true;
        const response = await this.$axios.get(`/cities`);

        this.setCities(response.data);
      } catch (error) {
        console.log(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loadingCities = false;
      }
    },
    setCities(data) {
      const cpData = [...data];

      this.cities = cpData.map((c) => {
        return {
          text: `${c.name} - ${c.State.uf}`,
          value: c.id,
        };
      });
    },

    closeSuccessDialog() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
      else if (this.actionAfterOperation === "stay") this.successDialog = false;
    },

    clearForm() {
      this.form = {};
    },
  },
  created() {
    this.loadCities();
    if (this.mod === "update") this.loadCompanyBranchData();
  },
};
</script>

<style>
</style>